<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form-group>
          <label for="code">Doanh nghiệp vi phạm<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="type_accident"
            rules="required"
            :custom-messages="type_accident"
          >
            <v-select
              v-model="dataInput.bussinessId"
              :reduce="item => item.id"
              label="name"
              :options="dataCombobox || []"
              placeholder="Doanh nghiệp vi phạm"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form>

          <b-form-group
            label-for="description"
          >
            <label for="description">Ngày vi phạm<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="dateViolate"
              rules="required"
              :custom-messages="dateViolate"
            >
              <date-time-picker
                v-model="dataInput.dateViolate"
                :disabledInput="true"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider></b-form-group>
          <b-form-group>
            <label>Hành vi vi phạm<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="name"
            >
              <b-form-textarea
                v-model="dataInput.content"
                :state="errors.length > 0 ? false:null"
                rows="3"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetailInjury: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      code: {
        required: 'Mã số là bắt buộc',
      },
      name: {
        required: 'Tên loại tai nạn là bắt buộc',
      },
      type_accident: {
        required: 'Chọn loại tai nạn cha là bắt buộc',
      },
      dateViolate: {
        required: 'Ngày tai nạn là bắt buộc',
      },
      dataInput: {
        bussinessId: '',
        dateViolate: '',
        content: '',
      },
      dataCombobox: [],
    }
  },
  watch: {
    dataDetailInjury(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          bussinessId: this.dataDetailInjury.bussinessId,
          dateViolate: this.dataDetailInjury.dateViolate,
          content: this.dataDetailInjury.content,
        }
      }
    },
  },
  created() {
    this.fetchDataCombobox()
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        bussinessId: '',
        dateViolate: '',
        content: '',
      }
    },
    async fetchDataCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX)
      this.dataCombobox = data.data
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
